<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-col cols="12">
      <v-card-title style="background-color: transparent !important">
        <h3>مشاهده خدمت در منزل</h3>
      </v-card-title>
      <v-tabs class="" fixed-tabs background-color="transparent" v-model="tab">
        <v-tab class="tab-title"> جزئيات</v-tab>
        <v-tab class="tab-title" v-if="role == 'admin'"> پرداخت‌ها</v-tab>
        <v-tab class="tab-title"> ارسال گزارش </v-tab>
      </v-tabs>

      <v-card class="br-card pa-3">
        <v-card-text class="pe-0 ps-0">
          <div>
            <v-card>
              <v-tabs-items v-model="tab">
                <!-- جزئيات -->
                <v-tab-item>
                  <v-card class="pa-3">
                    <v-row class="time-row">
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-select
                          label="وضعیت"
                          outlined
                          dense
                          :items="outStatusOpt"
                          v-model="request.status"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-text-field
                          label="نام درخواست کننده"
                          outlined
                          dense
                          v-model="request.demander"
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-text-field
                          label="نسبت با بیمار"
                          outlined
                          dense
                          v-model="request.relation"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-text-field
                          label="نام بیمار"
                          outlined
                          dense
                          v-model="request.patientName"
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-text-field
                      label="آدرس محل سکونت"
                      outlined
                      dense
                      v-model="request.address"
                      disabled
                    ></v-text-field>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-text-field
                          type="number"
                          label="تلفن ثابت"
                          dir="ltr"
                          outlined
                          dense
                          v-model="request.tel"
                          disabled
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-text-field
                          label="شماره همراه"
                          dir="ltr"
                          outlined
                          dense
                          type="number"
                          placeholder="09-- --- ----"
                          :no-wheel="true"
                          v-model="request.mobile"
                          disabled
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-select
                          label="نوع خدمت"
                          outlined
                          dense
                          disabled
                          :items="requestTypes"
                          v-model="request.demandType"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-autocomplete
                          v-model="request.agent"
                          :items="computedAgents"
                          :disabled="request.demandType == ''"
                          item-text="label"
                          item-value="code"
                          label="مسئول خدمت"
                          outlined
                          dense
                          clearable
                          :placeholder="
                            request.demandType == ''
                              ? 'لطفا ابتدا نوع خدمت را مشخص کنید'
                              : 'مسئول مربوطه را انتخاب کنید'
                          "
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <span id="reqdate">
                          <v-text-field
                            outlined
                            dense
                            type="text"
                            append-icon="calendar_today"
                            v-model="request.date"
                            label=" تاریخ درخواستی "
                            :editable="true"
                            class="date-input"
                            disabled
                          >
                          </v-text-field>
                        </span>

                        <!-- <date-picker
                          v-model="request.date"
                          element="reqdate"
                          color="#00a7b7"
                        /> -->
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <span id="reqclock">
                          <v-text-field
                            outlined
                            dense
                            type="text"
                            append-icon="schedule"
                            v-model="request.clock"
                            label=" ساعت درخواستی "
                            :editable="true"
                            class="date-input"
                            disabled
                          >
                          </v-text-field>
                        </span>

                        <!-- <date-picker
                          v-model="request.clock"
                          element="reqclock"
                          color="#00a7b7"
                          type="time"
                        /> -->
                      </v-col>
                    </v-row>
                    <v-row v-if="request.status == 'اتمام'">
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <span id="finaldate">
                          <v-text-field
                            outlined
                            dense
                            type="text"
                            append-icon="calendar_today"
                            v-model="request.finalDate"
                            label=" تاریخ نهایی "
                            :editable="true"
                            class="date-input"
                          >
                          </v-text-field>
                        </span>

                        <date-picker
                          v-model="request.finalDate"
                          element="finaldate"
                          color="#00a7b7"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <span id="finaltime">
                          <v-text-field
                            outlined
                            dense
                            type="text"
                            append-icon="schedule"
                            v-model="request.finalTime"
                            label=" ساعت نهایی "
                            :editable="true"
                            class="date-input"
                          >
                          </v-text-field>
                        </span>

                        <date-picker
                          v-model="request.finalTime"
                          element="finaltime"
                          color="#00a7b7"
                          type="time"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="کارمند پذیرش"
                          outlined
                          dense
                          v-model="request.receptionName"
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-card>
                      <v-card-title class="pe-0 ps-0">
                        <h3>خدمات درخواستی</h3></v-card-title
                      >
                      <v-card-text class="pe-0 ps-0">
                        <div>
                          <b-table
                            :fields="serviceFields"
                            :items="newItems"
                            :current-page="newCurrentPage"
                            :filter="newFilter"
                            @filtered="onFiltered"
                          >
                            <template v-slot:head()="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ data.label }}
                              </div>
                            </template>
                            <template v-slot:cell()="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ data.value }}
                              </div>
                            </template>
                            <template v-slot:cell(index)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{
                                  data.index +
                                  newPerPage * (newCurrentPage - 1) +
                                  1
                                }}
                              </div>
                            </template>
                            <template v-slot:cell(clinicShare)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ Number(data.value).toLocaleString() }}
                              </div>
                            </template>
                            <template v-slot:cell(description)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{
                                  data.item.description.substr(0, 100) +
                                  (data.item.description.length > 100
                                    ? "..."
                                    : "")
                                }}
                              </div>
                            </template>
                            <template v-slot:cell(patientCost)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ Number(data.value).toLocaleString() }}
                              </div>
                            </template>
                            <template v-slot:cell(cost)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ Number(data.value).toLocaleString() }}
                              </div>
                            </template>
                            <div
                              slot="table-busy"
                              class="text-center primary--text my-2"
                            >
                              <v-progress-circular
                                indeterminate
                                color="primary"
                                class="align-middle"
                              ></v-progress-circular>
                            </div>
                          </b-table>
                          <v-pagination
                            v-model="newCurrentPage"
                            :length="Math.ceil(newTotalRows / newPerPage)"
                            :total-visible="5"
                            prev-icon="arrow_back"
                            next-icon="arrow_forward"
                          ></v-pagination>
                          <hr />
                          <h5 class="text-success mb-3 ms-3 me-3">
                            <strong
                              >قیمت کل:
                              {{
                                newItems
                                  .map((x) => x.cost)
                                  .reduce(
                                    (a, b) => parseInt(a) + parseInt(b),
                                    0
                                  )
                                  .toLocaleString()
                              }} </strong
                            >ریال
                          </h5>
                        </div>
                      </v-card-text>
                    </v-card>
                    <v-btn
                      class="primary-btn submit-btn"
                      @click="submit()"
                      :disabled="
                        request.status == 'لغو' ? false : !request.agent
                      "
                      >ثبت
                    </v-btn>
                  </v-card>
                </v-tab-item>
                <v-tab-item v-if="role == 'admin'">
                  <v-card class="pa-3">
                    <b-table
                      responsive
                      show-empty
                      :fields="paymentFields"
                      :items="Items"
                      empty-text="پرداختی برای نمایش وجود ندارد"
                      :busy="paymentBusy"
                      :current-page="paymentCurrentPage"
                      :per-page="paymentPerPage"
                      @row-clicked="onRowSelected"
                      tbody-tr-class="trClass"
                    >
                      <template v-slot:head()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.label }}
                        </div>
                      </template>
                      <template v-slot:cell()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.value }}
                        </div>
                      </template>
                      <template v-slot:cell(amount)="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ Number(data.value).toLocaleString() }}
                        </div>
                      </template>
                      <template v-slot:cell(transactionFee)="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{
                            data.item.transferType == "چک" ||
                            data.item.transferType == "کارت"
                              ? Number(data.value).toLocaleString()
                              : "-"
                          }}
                        </div>
                      </template>
                      <template v-slot:cell(description)="data">
                        <div style="text-align: center; vertical-align: middle">
                          <v-tooltip
                            right
                            max-width="600"
                            v-if="deviceType != 'mobile'"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">
                                {{
                                  data.value.length > 20
                                    ? data.value.slice(0, 20) + "..."
                                    : data.value
                                }}
                              </span>
                            </template>
                            <p class="text-right p-2 text-justify">
                              {{ data.value }}
                            </p>
                          </v-tooltip>
                          <p v-else>
                            {{
                              data.value.length > 20
                                ? data.value.slice(0, 20) + "..."
                                : data.value
                            }}
                          </p>
                        </div>
                      </template>
                      <template v-slot:cell(index)="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{
                            data.index + paymentPerPage * (paymentCurrentPage - 1) + 1
                          }}
                        </div>
                      </template>
                      <div
                        slot="table-busy"
                        class="text-center primary--text my-2"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                          class="align-middle"
                        ></v-progress-circular>
                      </div>
                    </b-table>
                    <v-pagination
                      v-model="paymentCurrentPage"
                      :length="Math.ceil(TotalRows / paymentPerPage)"
                      :total-visible="5"
                      prev-icon="arrow_back"
                      next-icon="arrow_forward"
                      style="float: center"
                    ></v-pagination>
                    <v-select
                      label="تعداد در هر صفحه:"
                      style="width: 150px"
                      v-model="paymentPerPage"
                      :items="perPageOptions"
                      item-text="text"
                      item-value="value"
                    ></v-select>
                  </v-card>
                </v-tab-item>
                <!-- ارسال گزارش -->
                <v-tab-item>
                  <v-card class="pa-2">
                    <v-file-input
                      @change="myUpload(file)"
                      v-model="file"
                      outlined
                      dense
                      label="بارگذاری گزارش"
                      prepend-inner-icon="upload_file"
                      prepend-icon=""
                      class="pa-1 file-input"
                    ></v-file-input>
                    <v-progress-linear
                      rounded
                      v-if="myFile.showProgress"
                      height="10"
                      :value="myFile.uploadPercentage"
                      class="mb-4 mt-0"
                    >
                    </v-progress-linear>
                    <v-row>
                      <br />
                      <span v-if="myFile.isUploaded">{{
                        myFile.fileName
                      }}</span>
                      <br />
                      <div
                        v-if="myFile.isUploaded"
                        color="green"
                        class="mt-3 pa-3"
                        style="font-size: 16px"
                      >
                        گزارش با موفقیت بارگذاری شد.
                      </div>
                    </v-row>
                    <br />
                    <!-- NOTE (t-files) to show added resume files on files tab -->
                    <b-table
                      responsive
                      show-empty
                      :fields="fileFields"
                      :items="reportFiles"
                      empty-text="فایلی وجود ندارد"
                      striped
                      :busy="fileBusy"
                      :filter="fileFilter"
                    >
                      <template v-slot:head()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.label }}
                        </div>
                      </template>
                      <template v-slot:cell()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.value }}
                        </div>
                      </template>
                      <template v-slot:cell(url)="data">
                        <div style="text-align: center; vertical-align: middle">
                          <a
                            v-if="data.value"
                            class="v-btn primary-btn pa-2"
                            :href="data.value"
                            target="_blank"
                            >دریافت فایل</a
                          >
                        </div>
                      </template>
                      <template v-slot:cell(index)="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.index + 1 }}
                        </div>
                      </template>
                      <div
                        slot="table-busy"
                        class="text-center primary--text my-2"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                          class="align-middle"
                        ></v-progress-circular>
                      </div>
                    </b-table>
                    <!-- NOTE (b-submit) to submit and save uploaded files -->
                    <v-btn
                      class="primary-btn submit-btn"
                      :disabled="!reportFiles"
                      @click="sendReport()"
                      >ذخیره</v-btn
                    >
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog v-model="paymentItemDialog" width="600">
      <v-card
        class="modal-card pa-3"
        style="max-height: 600px !important; overflow-y: auto;border-radius:20px"
      >
        <v-card-title class="mb-2">
          <h4> جزئیات پرداخت</h4>
          <v-spacer></v-spacer>
          <v-icon
            color="red"
            class="mb-2 ms-2"
            @click="
              paymentItemDialog = false;
              edited = false;
            "
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text>
          <v-row class="mt-3">
            <v-col v-if="selected.userName">
              <span class="h2 primary--text">نام کارمند:</span>
              <span class="h2"> {{ selected.userName }}</span>
            </v-col>
            <v-col v-if="selected.patientName">
              <span class="h2 primary--text">نام بیمار:</span>
              <span class="h2"> {{ selected.patientName }}</span>
            </v-col>
            <v-col>
              <span class="h2 primary--text">مبلغ :</span>
              <span class="h2">
                {{ Number(selected.amount).toLocaleString() }} ریال</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="h2 primary--text">تاریخ پرداخت:</span>
              <span class="h2"> {{ selected.date }}</span>
            </v-col>
            <v-col>
              <span class="h2 primary--text">ساعت پرداخت :</span>
              <span class="h2"> {{ selected.time }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-2">
              <span class="h2 primary--text">نحوه پرداخت :</span>
              <span class="h2"> {{ selected.transferType }}</span>
            </v-col>
          </v-row>
          <v-row
            v-if="
              selected.transferType == 'کارت' || selected.transferType == 'چک'
            "
          >
            <v-col>
              <div
                v-if="selected.transferType == 'کارت'"
                class="cardInfo-box ps-2 py-2 d-flex flex-column"
              >
                <div>
                  <span class="h3 primary--text">شماره کارت فرستنده :</span>
                  <span class="h3 float-end" dir="ltr">
                    {{ cardNumberSpacer(selected.cardNumberSender) }}</span
                  >
                </div>
                <div class="mt-5">
                  <span class="h3 primary--text">شماره کارت گیرنده :</span>
                  <span class="h3 float-end" dir="ltr">
                    {{ cardNumberSpacer(selected.cardNumberReciever) }}</span
                  >
                </div>
              </div>
              <div
                v-if="selected.transferType == 'چک'"
                class="cardInfo-box ps-2 py-2 d-flex flex-column"
              >
                <div>
                  <span class="h3 primary--text">نام بانک :</span>
                  <span class="h3"> {{ selected.chequeBankName }}</span>
                </div>
                <div class="mt-5">
                  <span class="h3 primary--text">مبلغ چک :</span>
                  <span class="h3">
                    {{ Number(selected.chequeAmount).toLocaleString() }}
                    ریال</span
                  >
                </div>
                <div class="mt-5">
                  <span class="h3 primary--text">شماره چک :</span>
                  <span class="h3"> {{ selected.chequeNumber }}</span>
                </div>
                <div class="mt-5">
                  <span class="h3 primary--text">شناسه صیاد :</span>
                  <span class="h3">
                    {{
                      selected.chequeSayyad ? selected.chequeSayyad : "--"
                    }}</span
                  >
                </div>
                <div class="mt-5">
                  <span class="h3 primary--text">تاریخ چک :</span>
                  <span class="h3"> {{ selected.chequeDueDate }}</span>
                </div>
                <div class="mt-5">
                  <span class="h3 primary--text">وضعیت چک :</span>
                  <span class="h3"> {{ selected.chequeStatus }}</span>
                </div>
                <div class="mt-5">
                  <span class="h3 primary--text">توضیحات چک :</span>
                  <div
                    class="h3"
                    style="line-height: 22px; text-align: justify"
                  >
                    {{
                      selected.chequeDescription
                        ? selected.chequeDescription
                        : "توضیحی ثبت نشده است."
                    }}
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row
            v-if="
              selected.transferType == 'چک' || selected.transferType == 'کارت'
            "
          >
            <v-col>
              <span class="h2 primary--text">کارمزد انتقال وجه:</span>
              <span class="h2">
                {{ Number(selected.transactionFee).toLocaleString() }} ریال
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="selected.files">
              <span class="h2 primary--text">
                فایل‌ها ({{ selected.files.length }}):</span
              >
              <span v-if="!selected.files.length" style="font-size: 15px">
                --
              </span>
              <div class="d-flex flex-column mt-1 ps-5">
                <ul>
                  <li
                    v-for="(item, index) in selected.files"
                    :key="index"
                    class="pt-5"
                    style="font-size: 15px"
                  >
                    <a :href="item.url" target="blank">{{
                      item.name.length > 50
                        ? item.name.slice(0, 50) + "..."
                        : item.name
                    }}</a>
                  </li>
                </ul>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="h2 primary--text">توضیحات :</span>
              <div
                class="h3 mt-1"
                style="line-height: 22px; text-align: justify"
              >
                {{ selected.description }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import { mapGetters } from "vuex";

export default {
  components: {
    datePicker: VuePersianDatetimePicker,
  },
  data() {
    return {
      tab: null,
      showProgress: false,
      uploadButDisable: false,
      variant: "info",
      striped: true,
      max: 100,
      uploadPercentage: 0,
      isUploaded: false,
      file: [],
      reportFiles: [],
      request: {},
      myFile: new Object({
        uploadPercentage: 0,
      }),
      fileBusy: false,
      fileFilter: "",
      busySubmit: true,
      busy: true,
      price: "",
      status: "",
      states: [
        { value: "ثبت شده", text: "ثبت شده" },
        { value: "در حال انجام", text: "در حال انجام" },
        { value: "اتمام", text: "اتمام" },
        { value: "لغو", text: "لغو" },
      ],
      docotorsWithout: [],
      insType: "",
      newCurrentPage: 1,
      newPerPage: 10,
      newTotalRows: "",
      paymentCurrentPage: 1,
      paymentPerPage: 10,
      TotalRows: "",
      newFilter: "",
      isBusy: false,
      gettingPeople: false,
      serviceFields: [
        { key: "index", label: "#" },
        { key: "name", label: "نام خدمت" },
        { key: "cost", label: "قیمت (ریال)" },
      ],
      requestTypes: [
        { value: "", text: "" },
        { value: "ویزیت پزشک در منزل", text: "ویزیت پزشک در منزل" },
        { value: "ویزیت پرستار در منزل", text: "ویزیت پرستار در منزل" },
        { value: "فیزیوتراپی در منزل", text: "فیزیوتراپی در منزل" },
        { value: "تحویل دارو در منزل", text: "تحویل دارو در منزل" },
      ],
      paymentOpt: [
        "حضوری-نقدی",
        "حضوری- پوز درمانگاه",
        "غیرحضوری-واریز به کارت بانکی درمانگاه",
      ],
      newItems: [],
      // reciept
      CurrentPage: 0,
      PerPage: 0,
      userService: "",
      reception: "",
      visitNo: "",
      userData: {},
      requestDate: "",
      userTotal: {},
      Fields: [
        { key: "name", label: "خدمت" },
        { key: "number", label: "تعداد" },
        { key: "cost", label: "قیمت" },
      ],
      paymentFields: [
        { key: "index", label: "#" },
        { key: "amount", label: "مبلغ (ریال)" },
        { key: "date", label: "تاریخ پرداخت" },
        { key: "time", label: "ساعت پرداخت" },
        { key: "transferType", label: "نحوه پرداخت" },
        { key: "transactionFee", label: "کارمزد انتقال وجه (ریال)" },
        { key: "description", label: "توضیحات" },
      ],
      Items: [],
      isMounted: false,
      // reciept
      drBusy: true,
      outStatusOpt: ["مراجعه پزشک", "ثبت شده", "گزارش پزشک", "اتمام", "لغو"],
      role: "",
      selected: {},
      paymentItemDialog: false,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      paymentBusy: true,
      deviceType: "",
    };
  },
  computed: {
    ...mapGetters(["drNames", "nurseNames"]),
    computedAgents() {
      if (this.request.demandType == "ویزیت پزشک در منزل") {
        return this.drNames;
      } else {
        return this.nurseNames;
      }
    },
    fileFields() {
      let fileFields =
        this.role == "admin"
          ? [
              { key: "index", label: "ردیف" },
              { key: "showName", label: "نام فایل" },
              { key: "url", label: "دریافت" },
            ]
          : [
              { key: "index", label: "ردیف" },
              { key: "showName", label: "نام فایل" },
            ];
      return fileFields;
    },
  },
  methods: {
    myUpload(file) {
      // NOTE (m-myUpload) to upload files
      this.myFile.showProgress = true;
      this.myFile.variant = "info";
      this.myFile.uploadPercentage = 0;
      this.myFile.isUploaded = false;
      this.myFile.uploadButDisable = true;
      var formData = new FormData();
      formData.append(file.name, file);

      this.$http
        .post(this.baseUrl + "/clinic/uploads/private", formData, {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
          onUploadProgress: ((progressEvent) => {
            this.myFile.uploadPercentage =
              Math.round((progressEvent.loaded * 100) / progressEvent.total) -
              1;
          }).bind(this),
        })
        .then((d) => {
          if (d.status == 200) {
            this.myFile.isUploaded = true;
            this.myFile.uploadPercentage = 100;
            this.myFile.striped = false;
            this.myFile.variant = "success";
            this.myFile.uploadButDisable = false;
            this.reportFiles.push(d.data[0]);
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    sendReport() {
      this.vLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/homeCare/visit/uploadReport",
          {
            outRequestId: this.$route.params.id,
            files: JSON.stringify(this.reportFiles),
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.vLoading = false;
            this.toast("ارسال گزارش با موفقیت انجام شد.", "success");
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.vLoading = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
        });
    },
    requestInfo() {
      //NOTE getting request's info data from server when page mount
      this.vLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/homeCare/visit/getInfo",
          {
            outRequestId: this.$route.params.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.vLoading = false;
            this.request = res.data;

            this.newItems = res.data.services
              ? JSON.parse(res.data.services)
              : [];
            try {
              this.reportFiles = JSON.parse(res.data.files);
            } catch (error) {
              this.reportFiles = [];
            }
            this.newTotalRows = this.newItems.length;

            if (this.role == "admin") {
              this.Items = res.data.financials;
              this.TotalRows = this.Items.length;
              this.paymentBusy = false;
            }

            this.BusySubServices = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.vLoading = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
        });
    },
    submit() {
      //NOTE sending our data to server
      this.vLoading = true;
      this.busySubmit = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/homeCare/visit/edit",
          {
            outRequestId: this.$route.params.id,
            agentId: this.request.agent,
            status: this.request.status,
            finalTime:
              this.request.status == "اتمام" &&
              this.request.finalDate + this.request.finalTime,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.vLoading = false;
            this.busySubmit = false;
            this.toast("ویرایش با موفقیت به ثبت رسید", "success");
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.vLoading = false;
            this.busySubmit = false;
          }
        })

        .catch((err) => {
          console.log(err.response);
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
          this.busySubmit = false;
        });
    },
    cardNumberSpacer(number) {
      number = number.match(new RegExp(".{1,4}", "g")).join("-");
      return number;
    },
    onRowSelected(item) {
      this.selected = item;

      this.paymentItemDialog = true;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.newTotalRows = filteredItems.length;
      this.newCurrentPage = 1;
    },
  },
  mounted() {
    if (window.screen.width < 950) {
      this.deviceType = "mobile";
    } else {
      if (document.body.clientWidth < 950) {
        this.deviceType = "mobile";
      } else {
        this.deviceType = "desktop";
      }
    }
    this.role = localStorage.getItem("role");
    this.requestInfo();
  },
};
</script>
<style lang="scss">
  .trClass {
    cursor: pointer;
    &:hover {
      background-color: rgb(236, 236, 236);
    }
    &.table-active {
      background-color: #fff !important;
    }
  }
</style>
